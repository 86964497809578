import React, { useContext } from "react";
import styled from "@emotion/styled";
import { rhythm, scale } from "utils/typography";
import { mq } from "utils/helper";
import Card from "components/card";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";

const H3 = styled.h3({
  ...scale(0.6),
  margin: 0,
  marginBottom: "1rem",
  textTransform: "capitalize",
});

const CardGroup = ({ title, items }) => {
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        maxWidth: rhythm(50),
        margin: "0 auto",
        paddingTop: rhythm(1.5),
        direction: isRTL ? "rtl" : "ltr",
        [mq[3]]: {
          margin: rhythm(1),
        },
        "&:last-child > hr": {
          display: "none",
        },
      }}
    >
      {!!title && <H3>{title}</H3>}
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridRowGap: rhythm(2.5),
          paddingBottom: rhythm(1.5),
          width: "103%",
          "@media only screen and (max-width: 1100px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
            width: "100%",
          },
          "@media only screen and (max-width: 840px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [mq[0]]: {
            display: "block",
          },
        }}
      >
        {items?.map(({ node }) => {
          return (
            node &&
            !(node?.frontmatter?.url && node?.frontmatter?.url === "hide") && (
              <Card node={node} key={node.fields.slug} locale={locale} />
            )
          );
        })}
      </div>
      <hr
        css={{
          backgroundColor: colors.primary,
        }}
      />
    </div>
  );
};

export default CardGroup;
