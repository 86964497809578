import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/layout";

import Seo from "components/seo";
import PageHeader from "components/PageHeader";
import { LocaleContext } from "context/locale-context";
import useTranslations from 'hooks/useTranslations';
import CardGroup from "components/investigations-page/CardGroup";

const Blog = ({ data }) => {
  const locale = useContext(LocaleContext);
  const tr = useTranslations();
  const status = `${data.blogposts.edges.length} ${tr("Blog posts")}`;
  const metadata = { title: "blog" };
  return (
    <Layout className={locale}>
      <Seo metadata={metadata} />
      <PageHeader
        title="Blog"
        desc=""
        status={status}
      />
      <CardGroup items={data.blogposts.edges} />
    </Layout>
  );
}

Blog.propTypes = {
  data: PropTypes.object,
};

export default Blog;

export const pageQuery = graphql`
  query BlogPosts($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    blogposts: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/content/blog/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            locale
          }
          frontmatter {
            title
            date
            desc
            dataset
            level
            type
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 260
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`;
